import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { Listing } from "../components/Listing"

const Listings = styled.div`
  margin: auto;
  margin-bottom: 1.45rem;
  max-width: 960px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
`

const asins = [
  "B07MGDXWS3",
  "B07MGDXWRY",
  "B07MGDW2XR",
  "B07MGDXVLG",
  "B07M8337M6",
  "B07M8333S5",
  "B07MQTN438",
  "B07NP2ZXTC",
  "B07NP2H7H2",
  "B07Q24XFWD",
  "B07PWPFFZG",
]

const renderListings = () => {
  return asins.map(ASIN => <Listing ASIN={ASIN} key={ASIN} />)
}

const IndexPage = () => {
  const ydyRef = React.createRef()

  const scrollIntoView = () => {
    ydyRef.current.scrollIntoView({ behavior: "smooth" })
  }
  return (
    <Layout scrollIntoView={scrollIntoView}>
      <SEO title="Home" keywords={[`Salty Floral`, `Rude`, `Flowers`]} />
      <h1 id="good-shit">You do you</h1>
      {/* <Image /> */}
      <Listings ref={ydyRef}>{renderListings()}</Listings>
      {/* <Link to="/page-2/">Go to page 2</Link> */}
    </Layout>
  )
}

export default IndexPage
