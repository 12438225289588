import React from "react"
import styled from "styled-components"

const StyledImage = styled.img`
  border: none;
  width: 1;
  height: 1;
  margin: 0px;
`
const ListingContainer = styled.div`
  border: 2px solid transparent;
  &:hover {
    border: 2px solid black;
  }
`

export const Listing = ({ ASIN = "B07MGDXWS3" }) => {
  return (
    <ListingContainer>
      <a
        target="_blank"
        href={`https://www.amazon.com/gp/offer-listing/${ASIN}/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=${ASIN}&linkCode=am2&tag=thirtysevende-20&linkId=05297f9a855dadba623a48e19715b7bd`}
      >
        <StyledImage
          border="0"
          src={`//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=${ASIN}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=thirtysevende-20`}
        />
      </a>
      <StyledImage
        src={`//ir-na.amazon-adsystem.com/e/ir?t=thirtysevende-20&l=am2&o=1&a=${ASIN}`}
        alt=""
      />
    </ListingContainer>
  )
}
{
  /* <a target="_blank"  href="https://www.amazon.com/gp/offer-listing/B07MGDXWRY/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07MGDXWRY&linkCode=am2&tag=thirtysevende-20&linkId=4ed55cebfea9a59563c72033044f54d6"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07MGDXWRY&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=thirtysevende-20" ></a><img src="//ir-na.amazon-adsystem.com/e/ir?t=thirtysevende-20&l=am2&o=1&a=B07MGDXWRY" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" /> */
}
{
  /* <a target="_blank"  href="https://www.amazon.com/gp/offer-listing/B07MGDXWRY/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07MGDXWRY&linkCode=am2&tag=thirtysevende-20&linkId=81f9b66993e3ac4f92c6dc5ecb843b38"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07MGDXWRY&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=thirtysevende-20" ></a><img src="//ir-na.amazon-adsystem.com/e/ir?t=thirtysevende-20&l=am2&o=1&a=B07MGDXWRY" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" /> */
}
